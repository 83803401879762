<template>
	<footer class="main-footer bg-white d-flex align-items-center">
		<div
			class="row d-flex flex-column x-large-flex-row x-large-align-items-center justify-content-center x-large justify-content-between large-px-1"
		>
			<div class="main-footer__links">
				<a
					href="https://www.sidearmsports.com/termsofservice/"
					target="_blank"
					class="text-decoration-none text-black"
					>Terms of Service</a
				>
				<span class="d-inline-block mx-1"></span>
				<a
					href="https://www.sidearmsports.com/privacy-policy/"
					target="_blank"
					class="text-decoration-none text-black"
					>Privacy Policy</a
				>
			</div>

			<div
				class="main-footer__logos d-flex flex-wrap large-flex-nowrap large-flex-row large-align-items-center large-mt-4 x-large-mt-0"
			>
				<div class="main-footer__logo" style="width:100%;">
					<a href="https://www.sidearmsports.com/" target="_blank">
						<img
							src="https://images.sidearmsports.com/logos.sidearmsports.com/sidearm/sidearm-horiz_on-light.svg"
							alt="Sidearm Sports"
						/>
					</a>
				</div>
			</div>
		</div>
	</footer>
</template>

<script>
export default {
	name: 'MainFooter',
};
</script>

<style lang="scss" scoped>
@import '@/styles/app';

.main-footer {
	height: 150px;

	&__links {
		&:hover {
			text-decoration: underline;
		}

		> span {
			background-color: $color-border;
			height: 1em;
			width: 1px;
		}
	}

	&__logos {
		> span {
			align-self: stretch;
			background-color: $color-border;
			margin: 0 $spacer * 2;
			width: 2px;
		}
	}

	&__logo {
		align-items: center;
		display: flex;
		justify-content: center;

		> svg {
			max-width: 100%;
		}
	}

	@media (max-width: 1280px) {
		padding-top: $spacer * 4;
		padding-bottom: $spacer * 4;
		height: initial;
	}

	@media (max-width: #{$breakpoint-medium-down}) {
		padding: 0;

		&__links {
			> span {
				display: none;
			}

			> a {
				border-top: 1px solid $color-border;
				display: block;
				padding: $spacer $spacer * 2;
			}
		}

		&__logos {
			> span {
				display: none;
			}
		}

		&__logo {
			border-top: 1px solid $color-border;
			padding: $spacer * 2;
			text-align: center;
			width: 50%;

			&:nth-of-type(odd):not(:last-child) {
				border-right: 1px solid $color-border;
			}
		}
	}
}
</style>
